import { Link } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export default function NotFoundPage({ location }) {
  const title = "404 | What's The Motive";
  const description = "Page not found.";

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "en-GB" }}
        title={title}
        meta={[
          { name: "description", content: description },
          { property: "og:url", content: location.href },
          { property: "og:title", content: title },
          { property: "og:description", content: description },
          { property: "og:image", content: "" },
          { name: "facebook-domain-verification", content: "" },
        ]}
      />
      <section className="about__section">
        <h1>Page Not Found</h1>
        <p>
          <Link to="/">Back to home</Link>
        </p>
      </section>
    </>
  );
}
